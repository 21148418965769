#detailslbl{
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #D1D0CB;
    padding: 0px;
    margin: 0px 0px 0px 10px;
}
.addDataBtn{
    background: linear-gradient(90deg, #41A284 0%, #098067 100%);
    border: 1.12px solid #006838;
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #FFFFFF;
    padding: 4px 10px 4px 10px;
    border-radius: 8px;
}
.addContainer{
    position: absolute;
    left: 10.77%;
    right: 17.27%;
    top: 13.25%;
    bottom: 5.48%;
    background: linear-gradient(343.23deg, rgba(167, 168, 169, 0.8) -15.63%, #FFFFFF 115.63%);
    border-radius: 12px;
}
.addTitle{
    font-family: 'Roboto Slab';
    font-style: normal;
    font-weight: 900;
    font-size: 21px;
    line-height: 28px;
    color: #0A1330;
    width: 200px;
    left: 28px;
    position: absolute;
}
.addDataTitle{
    position: absolute;
    font-family: 'Roboto Slab';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: #0A1330;
    left: 20px;
    top: 5px;
}
.dataPlnBtn:focus{
    background: linear-gradient(90deg, #41A284 0%, #098067 100%);
    border: 1.641px solid #006838;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 31px;
    color: #D1D0CB;
    opacity: 1;
    width: 170px;
    height: 40px;
    padding: "10px";
    /* margin: "10px"; */
    display: flex;
    justify-content: space-between;
    border-radius: 5px;
}
.dataPlnBtn{
    /* background: #D3D2CD;
    opacity: 0.5;
    border: 1px solid #000000;
    box-sizing: border-box;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 20.445px;
    line-height: 31px;
    color: #414042;
    border-radius: 6px;
    width: 150px;
    height: 36px;
    margin: 5px;
     */
    background: #D3D2CD;
    opacity: 0.8;
    border: 1px solid #000000;
    box-sizing: border-box;
    width: 170px;
    height: 40px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 31px;
    text-align: left;
    padding: "10px";
    /* margin: "10px"; */
    display: flex;
    justify-content: space-between;
    color: #414042;
    border-radius: 5px;
}
.addSaveChanges{
    background: #0A1330;
    position: absolute;
    right: 4%;
    bottom: 8%;
    font-family: 'Roboto Slab';
    font-style: normal;
    font-weight: 900;
    font-size: 21px;
    line-height: 28px;
    color: #FFFFFF;
    width: 166px;
    height: 32.6px;
    border: 0px;
}
.colData{
    max-width: 170px !important;
}
.layouta{
    display: grid;
    place-items: center;
}
.cont{
    margin-right: 0px;
    padding-right: 3%;
    display: flex;
    flex-direction: column;
}
.conta{
    display: grid;
    place-items: center;
    position: relative;
}
.contb{
    display: flex;
    flex-wrap:wrap;
}

.backBtn{
    font-family: Roboto Slab;
    font-style: normal;
    font-weight: 900;
    font-size: 21px;
    line-height: 28px;
    border-radius: 0%;
    position: absolute;
    right: 4.81%;
    bottom: 0%;
    color: #FFFFFF;
    background: #0A1330;
    max-width: 101px;
    height: 40px;
    border: 0px;
}
.liveBtn{
    background: #0A1330;
    font-family: 'Roboto Slab';
    font-style: normal;
    font-weight: 900;
    font-size: 14px;
    line-height: 18px;
    color: #D3D2CD;
    padding: 4px 10px 4px 10px;
    border-radius: 5px;
}
.selectedProductPlan{
    background: linear-gradient(90deg, #41A284 0%, #098067 100%);
    border: 1.641px solid #006838;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 26.445px;
    line-height: 31px;
    color: #D1D0CB;
    border-radius: 4px;
    padding: 0 1% 0 1%;
}
#txtright {
    font-family: Roboto;
    font-style: italic;
    font-weight: 400;
    font-size: 13.223px;
    line-height: 15px;
    text-align: right;
    color: #414042;
    padding-top: 10%;
}

.layout{
    height: 100%;
    width: 100%;
    display: grid;
    place-items: center;
    /* background: linear-gradient(343.23deg, rgba(167, 168, 169, 0.8) -15.63%, #FFFFFF 115.63%); */
}
.containerM{
    position: relative;
    display: flex;
    flex-direction: column;
    background: linear-gradient(343.23deg, rgba(167, 168, 169, 0.8) -15.63%, #FFFFFF 115.63%);
    padding: 36px 48px;
    /* box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px; */
    border-radius: 20px;
    text-align: center;
    width: 65%;
    height: 80%;
    left: -5%;
    top: 15%;
}
.colDataPlan{
    place-items: left;
    max-width: 250px;
}
.cardDataPlan{
    background: #E3E2DD;
    border: 1px solid #7D7F80;
    box-sizing: border-box;
    width: 180px;
    height: 200px;
    border-radius: 5px;
    place-items: left;
    margin-left: 0%;
}
.dataPlnDescription{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 42px;
    color: #0A1330; 
    text-align: left;
}
.dataPlnCharge{
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 400;
    font-size: 18px;
    text-align: left;
    line-height: 21px;
    color: #0A1330;

}
.dataPlnText{
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    text-align: left;
    color: #0A1330;
}
.currentBtn{
    border: 1px solid #949697;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #949697;
    border-radius: 8px;

}
.boxC{
    display: grid;
    margin-top: 20px;
    place-items: center;
    position: relative;
    height: 70px;
    border-radius: 20px;
}
.bar1{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 26.445px;
    line-height: 31px;
    color: #D1D0CB;
}
.bar2{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 13.223px;
    line-height: 15px;
    color: #D1D0CB;
}
.bar3{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 13.223px;
    line-height: 15px;
    color: #D1D0CB;
}