.dropbtn {
    background-color: #3498DB;
    color: white;
    padding: 16px;
    font-size: 16px;
    border: none;
    cursor: pointer;
  }
  
  .dropbtn:hover, .dropbtn:focus {
    background-color: #2980B9;
  }
  
  .dropdown {
    position: relative;
    display: inline-block;
  }
  .dropdown-menu{
    /* border: 1.319px solid #7D7F80; */
    border-radius: 0;
    inset: auto auto 0px -50px !important; 
    padding: 0 !important;
    margin: 0 !important;
  }
  .page-link {
    position: relative;
    display: block;
    padding: 5px;
    margin-left: -1px;
    line-height: 1.25;
    color: #D3D2CD !important;
    background-color: #0A1330;
    border: 0;
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    text-align: center;
}

.ppage-link, .page-link:hover, .page-link:active, .page-link:active:focus, .page-link:visited, .page-link:focus {     color: #D3D2CD;     background-color: #0A1330;        outline: none; }
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 160px;
    overflow: auto;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #0A1330;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: 0.25rem;
  }
  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  
  .dropdown a:hover {background-color: #ddd;}
  
  .show {display: block;}

  .pageBtn{
    background-color: transparent;
    border: 1px solid #FFFFFF;
    border-radius: 5px;
    width: 64px;
    height: 23px;
    padding: 0%;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #D3D2CD;
  }
  .pageBtn, .pageBtn:hover, .pageBtn:active, .pageBtn:active:focus, .pageBtn:visited, .pageBtn:focus {     color: #dcdcdc;     background-color: transparent;        outline: none; }
  .prevBtn{
    background: transparent;
    border: 1px solid #FFFFFF;
    color: #FFFFFF;
    border-radius: 5px;
    margin-right: 2px;
    width: 23px;
    height: 23px;
    margin-top: 4%;
    padding-top: 0%;
  }

  .nextBtn{
    
    background: transparent;
    border: 1px solid #FFFFFF;
    /* color: #FFFFFF; */
    border-radius: 5px;
    margin-left: 2px;
    width: 23px;
    height: 23px;
    margin-top: 4%;
    padding-top: 0%;
  }

  .andCol{
    max-width: 12%;
  }
  .andContainer{
    position: relative;
    left: 90%;
    top: 92.3%;
  }