#loginform {
    padding: 16px 16px;
    padding-left: 33px;
    margin-bottom: 32px;
    border: none;
    border-radius: 4px;
    font-family: Nunito;
    font-weight: 300px;
    font-size: 21px;
    background-color: transparent;
    color: #C5C5C6;
    border-bottom: 1px solid #7d7f80;
    width: 383px;
    border-radius: 0%;
}
#loginform:focus{
    outline: none !important;
    background-color: transparent !important;
}
#loginform:active{
    outline: none !important;
    background-color: transparent !important;
}
#loginlogo{
    position: absolute;
    left: 6.6%;
    right: 77.92%;
    top: 13.67%;
    bottom: 72.09%;
}
.forget{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    text-decoration-line: underline;
    color: #7D7F80;
    margin-top: 3%;
}
.btnlogin{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 900;
    font-size: 12px;
    line-height: 14px;
    background: #0A1330;
    border-radius: 10px;
    justify-content: center;
    color: #FFFFFF;
    width: 153px;
    height: 33.5px;
    margin-top: 5%;
}
.btnlogin:hover{
    color: #FFFFFF;
}
.btnlogin:active{
    opacity: 1;
}
.iconLoginComponent{
    margin: 19px 0px 8px 0px;
    /* border-bottom: 1px solid #7d7f80; */
    position: fixed;
}
.iconLogin{
    padding-left: 6px;
    /* position: absolute;
    left: 39.75%; */
}
.passwordIconComponent{
    margin: 19px 0px 8px 0px;
    position: fixed;
    /* margin: 17px 0px 8px 9px; 
    border-bottom: 1px solid #7d7f80;*/
}
.passwordIcon{
    padding-left: 10.5px;
}
.h{
    margin-top:240px;
    margin-bottom: 20px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 900;
    font-size: 41.195px;
    line-height: 48px;
    color: #FFFFFF;
    margin-left: -14px;
}
@media screen and (min-width: 2500px) {
    #loginform {
        font-size: 26px !important;
        width: 428px;
        border-radius: 0%;
    }
    .forget{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 19px;
        line-height: 21px;
        text-decoration-line: underline;
        color: #7D7F80;
        margin-top: 3%;
    }
    .btnlogin{
        font-size: 17px;
        width: 223px;
        height: 40.5px;
    }
    .iconLogin{
        margin-top: 10px;
      }
    .passwordIcon{
        margin-top: 10px;
    }
    .h{
        font-size: 46.195px;
    }
  }