.cleanBg {
    position: fixed; 
    top: 0; 
    left: 0; 
    min-width: 100%;
    min-height: 100%;
    z-index: -1;
  }
.cleanLogo{
    margin-top:100px;
}
.ioiCleanLogo{
    position: absolute;
    left: 1.6%;
    right: 93.32%;
    top: 2.11%;
    bottom: 92.96%;
}
.h1{
    margin-top:40px;
    margin-bottom: 20px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 900;
    font-size: 41.195px;
    line-height: 48px;
    color: #FFFFFF;
    margin-left: -14px;
}
.cleanBtnlogin{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 900;
    font-size: 12px;
    line-height: 14px;
    background: #000000;
    border-radius: 10px;
    justify-content: center;
    color: #FFFFFF;
    width: 153px;
    height: 33.5px;
    margin-top: 5%;
}
.cleanBtnlogin:hover{
    color: #FFFFFF;
}
.cleanBtnlogin:active{
    opacity: 1;
}