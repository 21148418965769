.chartContainer {
  margin: 0 auto;
  max-width: 100%;
  position: relative;
  min-width: 228px;
  max-width: 268px;
  
}
.chartContainerA {
  margin: 0 auto;
  max-width: 100%;
  position: relative;
  min-width: 228px;
  max-width: 268px;
}

.statistics-title {
  font-family: "Roboto Slab";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
  margin: 0;
  margin-bottom: 20px;
}
.statistics-title-clean {
  font-family: 'Jost';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
  margin: 0;
  margin-bottom: 20px;
}

.chartInsideTotalNumber {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 50%;
  right: 20%;
  left: 20%;
  z-index: 1;
  font-size: 37.182px;
  margin: 0;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  color: #8086A1;
}
.chartInsideTotalText {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 58%;
  right: 20%;
  left: 20%;
  z-index: 1;
  font-size: 18px;
  margin: 0;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14.163px;
  line-height: 17px;
  color: #8086A1;
}
.chartInsideTotalNumberClean {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 50%;
  right: 20%;
  left: 20%;
  z-index: 1;
  font-size: 37.182px;
  margin: 0;
  font-family: 'Jost';
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  color: #7D7F80;
}
.chartInsideTotalTextClean {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 58%;
  right: 20%;
  left: 20%;
  z-index: 1;
  font-size: 18px;
  margin: 0;
  font-family: 'Jost';
  font-style: normal;
  font-weight: 400;
  font-size: 14.163px;
  line-height: 17px;
  color: #7D7F80;
}
.chartInsideDefineNumber {
  display: flex;
  justify-content: center;
  position: absolute;

  top: 42.81%;
  right: 20%;
  left: 20%;
  z-index: 1;
  margin: 0;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 37.011px;
  line-height: 17px;
  color: #8086A1;
  ;
}
.chartInsideDefineText1 {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 52%;
  right: 20%;
  left: 45%;
  z-index: 1;
  font-size: 18px;
  margin: 0;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14.163px;
  line-height: 17px;
  color: #8086A1;
}
.chartInsideDefineText2 {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 57%;
  right: 20%;
  left: 57%;
  z-index: 1;
  font-size: 18px;
  margin: 0;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14.163px;
  line-height: 17px;
  color: #8086A1;
}
.chartInsideDefineText3 {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 62%;
  right: 20%;
  left: 46%;
  z-index: 1;
  font-size: 18px;
  margin: 0;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14.163px;
  line-height: 17px;
  color: #8086A1;
}

.chartInsideCircle{
  display: flex;
  justify-content: center;
  position: absolute;
  background: linear-gradient(90deg, #53678D 0%, #415173 11.4%, #151931 35.5%, #030217 44.8%);
  width: 195px;
  height: 195px;
  border-radius: 50%;
  z-index: -1;
  margin: 0;
  top: 23%;
  left: 13%;
}
@media screen and (min-width: 2500px) {
  .chartContainer {
    min-width: 295px;
    max-width: 295px;
  }
  .chartContainerA {
    position: relative;
    min-width: 295px;
    max-width: 295px;
    margin-top: 5%;
  }
  .statistics-title {
    font-size: 19px;
  }
  .chartInsideTotalNumber {
    font-size: 42.182px;
  }
  .chartInsideTotalText {
    font-size: 23px;
    font-size: 19.163px;
    text-align: center;
    align-items: center;
  }
  .chartInsideDefineNumber {
    font-size: 42.011px;
  }
  .chartInsideDefineText1 {
    font-size: 23px;
    font-size: 19.163px;
  }
  .chartInsideDefineText2 {
    font-size: 23px;
    font-size: 19.163px;
  }
  .chartInsideDefineText3 {
    font-size: 23px;
    font-size: 19.163px;
  }
  
  .chartInsideCircle{
    width: 205px;
    height: 205px;
    border-radius: 50%;
    top: 25%;
    left: 16%;
  }
}