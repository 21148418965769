.ticketTitle {
  font-family: "Nunito";
  font-style: italic;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #ffffff;
}
.scroll{
  overflow-y: scroll;
  flex: 1;
}
#titleInput {
  /* width: 915px; */
  height: 26px;
  background: rgba(227, 226, 221, 0.5);
  border: 0px;
  border-radius: 0%;
  outline: none;
  cursor: pointer;
  font-family: "Nunito";
  font-style: italic;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #d3d2cd;
}
#titleInput::placeholder {
  color: #d3d2cd;
  opacity: 0.5;
}

.ticketDetails {
  font-family: "Nunito";
  font-style: italic;
  font-weight: 300;
  font-size: 14px;
  line-height: 19px;
  color: #7d7f80;
}

#detailsInput {
  font-family: "Nunito";
  font-style: italic;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #ffffff;
  background: rgba(227, 226, 221, 0.5);
  border: 0px;
  border-radius: 0%;
  outline: none;
  height: 220px;
  cursor: pointer;
  min-width: 100%;
}

.screenshotText {
  font-family: "Nunito";
  font-style: italic;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #ffffff;
}

.image {
  box-shadow: 0 2px 25px #777;
}
.image-control {
  padding: 1em 2em;
  margin: 0;
  background-color: #eee;
  border: 3px dotted #777;
}
.image-control::-webkit-file-upload-button {
  border: none;
  box-shadow: 0 2px 15px #777;
  padding: 0.4em 0.7em;
  font-weight: bold;
  background-color: teal;
  color: #fff;
  cursor: pointer;
  transition: box-shadow 0.2s;
}

.image-control::-webkit-file-upload-button:hover {
  box-shadow: 0 2px 10px #777;
}

.sendBtn {
  font-family: "Roboto Slab";
  font-style: normal;
  font-weight: 900;
  font-size: 21px;
  line-height: 28px;
  background: #0a1330;
  color: #ffffff;
  width: 70px;
  height: 34px;
  position: absolute;
  right: 0%;
  bottom: 2%;
}
.backBtn {
  font-family: "Roboto Slab";
  font-style: normal;
  font-weight: 900;
  font-size: 21px;
  line-height: 28px;
  background: #0a1330;
  color: #ffffff;
  padding: 2px 0 0 14px;
  width: 80px;
  height: 34px;
  position: absolute;
  right: 5%;
  bottom: 2%;
}
.submitBtn {
  font-family: "Roboto Slab";
  font-style: normal;
  font-weight: 900;
  font-size: 21px;
  line-height: 28px;
  background: #0a1330;
  color: #ffffff;
  width: 180px;
  height: 34px;
  left: 5%;
  bottom: 2%;
  margin-top: 12px;
  margin-left: -16px;
}
.ticketStatusTitle {
  font-family: "Nunito";
  font-style: italic;
  font-weight: 300;
  font-size: 13px;
  line-height: 18px;
  /* identical to box height */

  color: #8e9091;
}

.ticketStatusDropdown{
    border-radius: 3px;
    color:#41B496
}
