.downloadTemlateBtn{
    background: #0A1330;
    font-family: 'Roboto Slab';
    font-style: normal;
    font-weight: 900;
    font-size: 21px;
    line-height: 28px;
    color: #FFFFFF;
    border-radius: 0%;
    width: 321px;
    height: 32.6px;
    padding-top: 0%;
}
.downloadTemlateBtn:hover{
    color: #FFFFFF;
}
.uploadTemlateBtn{
    background: #0A1330;
    font-family: 'Roboto Slab';
    font-style: normal;
    font-weight: 900;
    font-size: 21px;
    line-height: 28px;
    color: #FFFFFF;
    border-radius: 0%;
    width: 321px;
    height: 32.6px;
    padding-top: 0%;
}
.uploadTemlateBtn:hover{
    color: #FFFFFF;
}
.submitIccıd{
    font-family: 'Roboto Slab';
    font-style: normal;
    font-weight: 900;
    font-size: 21px;
    line-height: 28px;
    color: #FFFFFF;
    background: #0A1330;
    border-radius: 0%;
    width: 274.6px;
    height: 32.6px;
    padding-top: 0%;
    position: absolute;
    right: 5.34%;
    bottom: 0%;
}
#imgb{
    margin-left:"32px"; 
    padding-left:"27.6px"
}
@media screen and (min-width: 2500px) {
    .uploadTemlateBtn{
        font-size: 27px;
        width: 361px;
        height: 42.6px;
        padding-top: 3px;
    }
    .submitIccıd{
        font-size: 27px;
        width: 345.6px;
        padding-top: 3px;
        height: 42.6px;
    }
    #imgb{
        margin-left:"30px"; padding-left:"30.6px"
    }
  }
  input[type="file"] {
    display: none;
}
.custom-file-upload { 
    display: inline-block; 
    cursor: pointer;
}