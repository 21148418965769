.contentTitle {
  font-family: "Roboto Slab";
  font-style: normal;
  font-weight: 350;
  font-size: 19px;
  line-height: 18px;
  margin-bottom: 5%;
  
}

.contentDescription {
  /* min-height: auto; */
  margin-top: -3%;
  /* margin-bottom: 1%;  */
  margin-left: 0.2%;
  padding-left: 2%;
  padding-right: 10%;
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: #FFFFFF;
}
.contentDescriptionClean {
  /* min-height: auto; */
  margin-top: -3%;
  /* margin-bottom: 1%;  */
  margin-left: 0.2%;
  padding-left: 2%;
  padding-right: 2%;
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
}
.rowTitle {
  max-width: 50rem;
  min-width: 20rem;
}
