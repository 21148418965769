#validAlert{

    width: 36%;
    margin-top: -15px;
}
#addNextBtn{
    font-family: Roboto Slab;
    font-style: normal;
    font-weight: 900;
    font-size: 21px;
    line-height: 28px;
    border-radius: 0%;
    color: #FFFFFF;
    background: #0A1330;
    max-width: 100px;
    height: 40px;
    position: relative;
}