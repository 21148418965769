.containerDashboard{
    /* display: grid;
    grid-template-columns: repeat(3,1fr); */
    height: 100%;
}
.colA{
    /* align-self: stretch; */
    border-radius: 10%;
    max-width: 17.5%;
}
.colB{
    /* align-self: stretch; */
    border-radius: 10%;
    max-width: 65%;
    min-width: 60%;
    justify-content: center;
}
.colC{
    border-radius: 10%;
    width: 17.5%;
}
.boxOne {
    height: 50%;
}
.boxTwo{

}
.boxThree{
    grid-column-start: 2;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 3;
    position: relative;
}
.boxFour{
    height: 50%;
}
.boxFive{

}
.__react_component_tooltip {
    border-radius: 50% !important;
}
.boxApex{
    width: 83%;
    height: 500px !important;
    padding-left: 10%;
}
.boxMap{
    /* border: 0.25px solid #C3C2BD;
    margin: 0% 10% 0% 10%; */
    width: 83%;
    height: 400px !important;
    padding-left: 9%;
}
.stateA{
    text-align: center !important;
}
@media screen and (min-width: 2500px) {
    .colA{
        /* align-self: stretch; */
        /* border-radius: 10%;
        max-width: 700px; */
    }
    .colB{
        /* align-self: stretch; */
        /* border-radius: 10%;
        max-width: 130%; */
    }
    .colC{
        /* align-self: stretch; */
        /* border-radius: 10%;
        max-width: 550px;
        padding-left: 150px; */
    }
    .boxMap{
        /* border: 0.25px solid #C3C2BD;
        margin: 0% 10% 0% 10%; */
        width: 83%;
        height: 480px !important;
        padding-left: 19%;
    }
    .boxApex{
        width: 74%;
        height: 500px !important;
        padding-left: 17%;
    }
  }