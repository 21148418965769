.verticalBorder{
    border: 2px solid #D83A00;
    transform: rotate(90deg);
}

.bs-stepper-circle {
    display: inline-flex;
    align-content: left;
    justify-content: left;
    width: 150px;
    height: 25px;
    padding: .15rem 0 0 .45rem;
    margin: 0.25rem;
    line-height: 1em;
    color: #0A1330;
    background-color: #C4C4C4;
    border-radius: 0em;
    font-family: 'Roboto Slab';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-align: left !important;
}

.active .bs-stepper-circle {
    background-color: #0A1330;
    border: 0.5px solid #9D2A00;
    box-shadow: 0px 0px 10px #D83A00;
    color: #FFFFFF;
}

.bs-stepper .step-trigger {
    padding: 10px;
    text-decoration: none;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    background-color: transparent;
    border: none;
    border-radius: .25rem;
    transition: background-color .15s ease-out, color .15s ease-out;
}

.bs-stepper .step-trigger:focus {
    outline: none;
    /* outline: solid; */
}

.bs-stepper .step-trigger:hover {
    text-decoration: none;
}