.card-container.card{
    /* max-width: 900px !important; */
}
#updateUser{
    overflow-y:auto !important;
    /* overflow-y:scroll; */
}
/* .modal-dialog {
   
    width: inherit;
} */
.modal-dialog{
    max-width: 410px !important;
}
#selectrolee{
    padding: 0.65em 14.25em 0.55em 0.75em;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
}
#update{
    font-family: Roboto Slab;
    font-style: bold;
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;
    background-color:#D04102;
    color:#FFFFFF;
    border:0
}
#active{
    font-family: 'Roboto Slab';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #0A1330;
}
.titleMain{
left: 17.25%;
right: 67.33%;
top: 9.14%;
bottom: 87.75%;
font-family: 'Roboto Slab';
font-style: normal;
font-weight: 900;
font-size: 21px;
line-height: 28px;

color: #FFFFFF;
}
.titleMainClean{
    left: 17.25%;
    right: 67.33%;
    top: 9.14%;
    bottom: 87.75%;
    font-family: 'Jost';
    font-style: normal;
    font-weight: 900;
    font-size: 21px;
    line-height: 28px;
    color: #000000;
    }
@media screen and (min-width: 2500px) {
    .title {
        font-size: 26px;
    }
    #active{
        font-size: 19px;
    }
  }
#exampleModalLabel{
    font-family: Roboto Slab;
    font-style: bold;
    font-weight: 800;
    font-size: 21px;
    line-height: 18px;
}