.MuiPaper-root {
    background-color: #CDCCC7 !important;
}

.MUIDataTableSelectCell-headerCell-41 {
    background-color: #CDCCC7 !important;
}
.MUIDataTableHeadCell-fixedHeader-51 {
    background-color: #CDCCC7 !important;
}

.MuiButton-root {
    color: #8E9091 !important;
    padding: 6px 12px;
    font-family: 'Roboto Slab'!important;
    font-style: normal !important;
    font-weight: 800!important;
    font-size: 16px!important;
    line-height: 21px!important;
    /* text-align: center !important; */
}

.MuiTableCell-root {
    padding: 10px !important;
    /* text-align: center !important; */
    border-bottom: 1px solid rgba(10, 19, 48, 0.2) !important;
    font-family: 'Nunito' !important;
    font-style: normal !important;
    font-weight: 900 !important;
    font-size: 14px !important;
    line-height: 19px !important;
    color: #000000 !important;
}

.statusTicketT{
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #FFFFFF;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background: #0A1330;
    height: 40px;
    width: 100%;
}

.statusTicketO{
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #FFFFFF;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background: #D83A00;
    width: 100%;
    height: 38px;
}

.statusTicketS{
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #FFFFFF;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background: #41B496;
    width: 100%;
    height: 38px;
}

.numberTicket{
    background: #D3D2CD;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    font-family: 'Roboto Slab';
    font-style: normal;
    font-weight: 900;
    font-size: 14px;
    line-height: 18px;
    color: #0A1330;
    width: 76px;
    height: 38px;
}

.statusTicketRow{
    min-width: 185px;
    max-width: 300px;
}
.statusTicketCol{
    max-width: 330px;
}

@media screen and (min-width: 2500px) {
    .statusTicketRow{
        min-width: 185px;
        max-width: 350px;
    }
    .statusTicketCol{
        max-width: 380px;
    }
}




