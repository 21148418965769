.newIccıdContainer{
    max-width: 40%;
    padding-left: 0%;
    margin-left: -12px;
}
.clearBtn{
    background: #0A1330;
    font-family: 'Roboto Slab';
    font-style: normal;
    font-weight: 900;
    font-size: 21px;
    line-height: 28px;
    color: #FFFFFF;
    border-radius: 0%;
    width: 81px;
    height: 33px;
    padding-top: 0%;
}
.clearBtn:hover{
    color: #FFFFFF;
}
.createIccıdBtn{
    background: #0A1330;
    font-family: 'Roboto Slab';
    font-style: normal;
    font-weight: 900;
    font-size: 21px;
    line-height: 28px;
    color: #FFFFFF;
    border-radius: 0%;
    width: 155px;
    height: 33px;
    padding-top: 0%;
}
.createIccıdBtn:hover{
    color: #FFFFFF;
}
.bulkUploadBtn{
    background: #0A1330;
    font-family: 'Roboto Slab';
    font-style: normal;
    font-weight: 900;
    font-size: 21px;
    line-height: 28px;
    color: #FFFFFF;
    border-radius: 0%;
    width: 213px;
    height: 33px;
    padding-top: 0%;
}
.bulkUploadBtn:hover{
    color: #FFFFFF;
}
@media screen and (min-width: 2500px) {
    .clearBtn{
        font-size: 26px;
        width: 101px;
        height: 40px;
        padding-top: 0.5%;
    }
    .createIccıdBtn{
        font-size: 26px;
        width: 195px;
        height: 40px;
        padding-top: 0.5%;

    }
    .bulkUploadBtn{
        font-size: 26px;
        width: 243px;
        padding-top: 0.5%;
        height: 40px;
    }
  }