label {
  display: block;
  margin-top: 10px;
}

.card-container.card {
  max-width: 350px !important;
  padding: 40px 40px;
}

.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

#bg {
  position: fixed; 
  top: 0; 
  left: 0; 
  min-width: 100%;
  min-height: 100%;
  z-index: -1;
}

#menu {
  width: 250px;
  /* height: 650px;
  margin: 100px 50px 75px 0px; */
  margin-left: 0%;
  margin-top: 10%;
  text-decoration: none;
  font-family: Roboto;
  font-style: normal;
  font-weight: 900;
  font-size: 11.83px;
  line-height: 14px;
  
}
#menuHelp {
  text-decoration: none;
  font-family: Roboto;
  font-style: normal;
  font-weight: 900;
  font-size: 11.83px;
  line-height: 14px;
  color: #FFFFFF; 
}
a, a:hover, a:focus, a:active {
  text-decoration: none;
  color: #FFFFFF;
}
.menuLabel{
  color: #000000 !important;
}
.nav-link {
  color: #FFFFFF !important;
}
.nav-linka {
  color: #000000 !important;
  font-family: 'Jost';
  font-style: normal;
  font-weight: 500;
  font-size: 11.83px;
  line-height: 17px;
  text-decoration: none;
}
#menuitem{
  padding-left: 25px;
}
.container{
  left: 0px;
  width: 100%;
}
#ioilogo{
  margin: 10px 110px 50px 0px;

}
.menuCol{
  max-width: 270px;
}
.menuClk{
  left: 0%;
  top: 17%;
  width: 22px;
  height: 38px;
  background: #DB4401;
}
.menuClkA{
  position: absolute;
  left: 0%;
  top: 24.7%;
  width: 22px;
  height: 38px;
  background: #DB4401;
}
.menuClkB{
  position: absolute;
  left: 0%;
  top: 32%;
  width: 22px;
  height: 38px;
  background: #DB4401;
}
.menuClkC{
  position: absolute;
  left: 0%;
  top: 39%;
  width: 22px;
  height: 38px;
  background: #DB4401;
}
.menuClkD{
  position: absolute;
  left: 0%;
  top: 46.5%;
  width: 22px;
  height: 38px;
  background: #DB4401;
}
.menuClkE{
  position: absolute;
  left: 0%;
  top: 53.7%;
  width: 22px;
  height: 38px;
  background: #DB4401;
}
.menuClkF{
  position: absolute;
  left: 0%;
  top: 61.2%;
  width: 22px;
  height: 38px;
  background: #DB4401;
}
.menuClkG{
  position: absolute;
  left: 0%;
  top: 68.7%;
  width: 22px;
  height: 38px;
  background: #DB4401;
}

.menuitemSim{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 11.83px;
  line-height: 14px;
  color: #FFFFFF;
  padding-left: 30px;
  list-style: none;
  margin: 0px;
}
.menuitemSimClean{
  font-family: 'Jost';
  font-style: normal;
  font-weight: 500;
  font-size: 11.83px;
  line-height: 17px;
  color: #000000;
  padding-left: 30px;
  list-style: none;
  margin: 0px;
}

.plus{
  position: absolute;
  padding-top: 5px;
  padding-left: 4px;
}
.userInfo{
  margin-top: 5px;
  text-align: right;
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #FFFFFF;
}
.logOutT{
  margin-top: 0px;
  text-align: right;
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #CE4102;
}
.dealerImage{
  width: 100px;
  height: 100px;
}
.dealerImageA{
  width: 100px;
  height: 100px;
  background: #030419;
  margin-left: 12%;
}
.imageTxt1{
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 400;
  font-size: 35px;
  line-height: 32px;
  color: #FFFFFF;
}
.imageTxt2{
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 400;
  font-size: 35px;
  line-height: 32px;
  color: #FFFFFF;
}

@media screen and (min-width: 2500px) {
  #menu {
    font-size: 15.83px;
  }
  .menuitemSim{
    font-size: 15.83px;
  }
  #menuHelp {
    font-size: 15.83px;
  }
  #addLogo{
  width: 40px !important;
  height: 35px !important;
  }
}
