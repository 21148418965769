.planPackage{
  padding-right: 0%;
  margin-right: 0%;
}
.containerCard {
  font-family: "Roboto Slab";
  font-style: normal;
  font-weight: 500;
  font-size: 17.931px;
  line-height: 22px;
}
.containerCardClean {
  font-family: 'Jost';
  font-style: normal;
  font-weight: 500;
  font-size: 17.931px;
  line-height: 22px;
}
.rightT {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 23.34px;
  line-height: 26px;
  text-align: right;
}
.cardOne {
  max-width: 11.2rem !important;
  width: 100% !important;
  height: 2.1rem !important;
  margin-top: 0%;
  margin-bottom: 0%;
  border-radius: 0%;
  /* padding: 0%; */
  padding-top: 1.7%;
  padding-left: 4%;
  padding-right: 4%;
  cursor: pointer;
  color: #fafcff;
  margin: 2px;
}
.cardOpen {
  width: 16rem;
  height: 3.6rem !important; 
  margin-top: 0%;
  margin-bottom: 0%;
  border-radius: 0%; 
  padding-top: 1%;
  padding-left: 4%;
  padding-right: 4%;
  cursor: pointer;
  /* border: 1px solid #e1e4e8 !important; */
  color: #fafcff !important;
  position: relative;
  right: 27.7%;
  margin: 2px;
}
.cardOneClean {
  max-width: 11.2rem !important;
  width: 100% !important;
  height: 2.1rem !important;
  margin-top: 0%;
  margin-bottom: 0%;
  border-radius: 0%;
  /* padding: 0%; */
  padding-top: 1.7%;
  padding-left: 4%;
  padding-right: 4%;
  cursor: pointer;
  color: #fafcff;
  margin: 2px;
  background-color: black !important;
}
.cardOpenClean {
  width: 16rem;
  height: 3.6rem !important; 
  margin-top: 0%;
  margin-bottom: 0%;
  border-radius: 0%; 
  padding-top: 1%;
  padding-left: 4%;
  padding-right: 4%;
  cursor: pointer;
  /* border: 1px solid #e1e4e8 !important; */
  color: #fafcff !important;
  position: relative;
  right: 27.7%;
  margin: 2px;
  background-color: black !important;
}
#grad {
  /* background-image: linear-gradient(#e77d2a, #0a1330);
  max-width: 11.2rem; */
}
.txtA{
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  color: #FFFFFF;
  text-align: right;
  padding-top: 1%;
  margin-top: 0%;
}
.txtB{
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 12px;
  color: #FFFFFF;
  text-align: right;
}
.txtAClean{
  font-family: 'Jost';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 10px;
  color: #FFFFFF;
  text-align: right;
  padding-top: 1%;
  margin-top: 0%;
}
.txtBClean{
  font-family: 'Jost';
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 12px;
  color: #FFFFFF;
  text-align: right;
}
@media screen and (min-width: 2500px) {
  .cardOne {
    width: 100% !important;
    height: 42px !important;
    padding-top: 5%;
    padding-left: 4%;
    padding-right: 4%;
    cursor: pointer;
    margin: 2.2px;
  }
  .rightT {
    font-size: 25px !important;
  }
  .containerCard {
    font-size: 20px;
  }
  #grad {
    max-width: 13.6rem;
  }
  .cardOpen {
    width: 17.6rem;
    height: 3.6rem !important; 
    margin-top: 0%;
    margin-bottom: 0%;
    border-radius: 0%; 
    padding-top: 1.1%;
    padding-left: 4%;
    padding-right: 4%;
    cursor: pointer;
    color: #fafcff !important;
    position: relative;
    right: 47%;
    margin: 2.2px;
  }
}
