.main{ 
    padding-left: 320px;
    width: 80%;
    }

.labeltxt{
    font-family: "Roboto Slab";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #FFFFFF;
}
.labeltxtClean{
    font-family: 'Jost';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #000000;
}
.activatedbtn{
    position: static;
    width: "62,9px";
    height: "25,88px" !important;
    border: 0;
    border-radius: 0;
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    background: #0A1330;
    color: #FFFFFF;
    padding: 9px 10px 9px 10px;
}
.deactivatedbtn{
    position: static;
    width: "83,83px";
    height: "25,88px" !important;
    border: 0;
    border-radius: 0;    
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    padding: 9px 10px 9px 10px;
    color: #949697;
    background: #D3D2CD;
}
.activatedbtnClean{
    position: static;
    width: "62,9px";
    height: "25,88px" !important;
    border: 0;
    border-radius: 0;
    font-family: 'Jost';
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    background: #000000;
    color: #FFFFFF;
    padding: 9px 10px 9px 10px;
}
.deactivatedbtnClean{
    position: static;
    width: "83,83px";
    height: "25,88px" !important;
    border: 0;
    border-radius: 0;    
    font-family: "Jost";
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    padding: 9px 10px 9px 10px;
    color: #7D7F80;
    background: #C4C5C5;
}  
.activated{
    color:#3CB300;
}
.deactivated{
    color: #A08024;
}
.activatedClean{
    color:#B1CB32;
}
.deactivatedClean{
    color: #A08024;
}
.searchstyle{
    position: static ;
    max-width: "335,75px";
    height: "18,88px";
    border-radius: "2px";
    border: 0;
    font-family: Nunito;
    font-style: italic;
    font-weight: 300;
    font-size: 14px;
    line-height: 19px;
    background: #0A1330;
    color: #949697;
}
.searchstyleClean{
    position: static ;
    max-width: "335,75px";
    height: "18,88px";
    border-radius: "2px";
    border: 0;
    font-family: 'Jost';
    font-style: italic;
    font-weight: 300;
    font-size: 14px;
    line-height: 19px;
    background: #000000;
    color: #7D7F80;
}
#searchicon{
    border: 0;
}
#addUserButton{
    background: linear-gradient(90deg, #41A486 0%, #098067 100%);
    border: 1.066px solid #006838;
    margin-top: 35px;
    /* padding-top: 1.5px; */
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    color: #FFFFFF;
    padding: 7px 11px 7px 11px;
}
.thead{
    font-family: 'Roboto Slab';
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 21px;
    color: #8E9091;
    border: 0;
    text-align: center;
    background: transparent !important;
}
.theadClean{
    font-family: 'Jost';
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 21px;
    color: #7D7F80;
    border: 0;
    text-align: center;
    background: transparent !important;
}
.tablebody{
    background: #D3D2CD ;
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    
    color: #231F20;
}
.table td{
    border-bottom: 1.319px solid #7D7F80;
    
}
.table tr{
    text-align: center;
}
.table-striped > tbody > tr:nth-child(even) > td, .table-striped > tbody > tr:nth-child(even) > th {
    background: #0A1330;
    color: #FFFFFF;
 }
 
#savechanges{
    right: "120px";
    font-family: Roboto Slab;
    font-style: normal;
    font-weight: 900;
    font-size: 21px;
    line-height: 28px;
    border: 0;
    border-radius: 0;

    color: #FFFFFF;
    background: #0A1330;
}
#jdLogo{
    margin-right: 50px;
}
.maincontainer{
    width: 95%;
}
.a{
    background: #E3E2DD ;
    border-radius: 12px;
    width: 100%;
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #231F20;
}
.aClean{
    background: #C4C5C5 ;
    border-radius: 12px;
    width: 100%;
    font-family: 'Jost';
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #000000;
}
tr.border_bottom  {
    border-top: 1.319px solid #8F9193;
  }
  .dropdown-menu{
    /* border: 1.319px solid #7D7F80; */
    border-radius: 0;
    inset: auto auto 0px -50px !important; 
    padding: 0 !important;
    margin: 0 !important;
  }
  .updateButton{
    background: #0A1330;
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;  
    color: #D3D2CD;
    padding: 10px;
    cursor: pointer;
  }
  .updateButton:hover{
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    color: #0A1330;
    background: #D3D2CD;
    padding: 10px;
    cursor: pointer;
  }
  .updateButtonClean{
    background: #000000;
    font-family: 'Jost';
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;  
    color: #C4C5C5;
    padding: 10px;
    cursor: pointer;
  }
  .updateButtonClean:hover{
    font-family: 'Jost';
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    color: #000000;
    background: #C4C5C5;
    padding: 10px;
    cursor: pointer;
  }
  .deleteButton{
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    color: #0A1330;
    background: #D3D2CD;
    padding: 10px;
    cursor: pointer;
  }

.status{
    max-width: 220px;
}  
.search{
    max-width: 540px;
}
    
.statusBill{
    max-width: 200px;
} 

#saveChangesBtn{
    font-family: 'Roboto Slab';
    font-style: normal;
    font-weight: 900;
    font-size: 21px;
    line-height: 28px;
    background: #0A1330;
    color: #FFFFFF;
    width: 167px !important;
    height: 33px !important;
    border-radius: 0px;
    position: relative;
    left: 90%;
    top: 100%;
    padding-top: 1px;
}
@media screen and (min-width: 2500px) {
    .labeltxt{
        font-size: 19px;
    }
    .activatedbtn{
        font-size: 16px;
        padding-bottom: 11.5px;
    }
    .deactivatedbtn{
        font-size: 16px;
    }
    .searchstyle{
        font-size: 16px;
    }
    #addUserButton{
        font-size: 16px !important;
        padding: 7.5px 12px 7.5px 12px !important;
    }
    .a{
        font-size: 17px;
    }
    .thead{
        font-size: 19px;
    }
    .tablebody{
        font-size: 17px;
    }
    #saveChangesBtn{
        font-size: 26px;
        width: 207px !important;
        height: 40px !important;
    }
    .search{
        max-width: 640px;
    }
    .status{
        max-width: 260px;
    } 
  }