* {
    box-sizing: border-box;
  }

  .v1223_121 {
    width: 726px;
    height: 785px;
    opacity: 1;
    position: absolute;
    overflow: hidden;
  }
  .v1223_123 {
    width: 726px;
    height: 785px;
    opacity: 1;
    position: absolute;
    top: 0px;
    left: 0px;
    overflow: hidden;
  }
  .v1223_132 {
    width: 726px;
    height: 785px;
    /* background: rgba(255,255,255,1); */
    opacity: 1;
    position: absolute;
    top: 0px;
    left: 0px;
  }
  .v1223_133 {
    width: 246px;
    color: rgba(255,255,255,1);
    position: absolute;
    top: 138px;
    left: 237px;
    font-family: Roboto Slab;
    font-weight: Regular;
    font-size: 21px;
    opacity: 1;
    text-align: left;
  }
  .v1223_134 {
    width: 235px;
    color: rgba(125,127,128,1);
    position: absolute;
    top: 150px;
    left: 246px;
    font-family: Roboto Slab;
    font-weight: Black;
    font-size: 24px;
    opacity: 1;
    text-align: left;
  }
  .v1223_135 {
    width: 331px;
    color: rgba(125,127,128,1);
    position: absolute;
    top: 185px;
    left: 209px;
    font-family: Nunito;
    font-weight: Regular;
    font-size: 14px;
    opacity: 1;
    text-align: left;
  }
  .v1223_136 {
    width: 442px;
    color: rgba(125,127,128,1);
    position: absolute;
    top: 378px;
    left: 142px;
    font-family: Nunito;
    font-weight: Regular;
    font-size: 14px;
    opacity: 1;
    text-align: left;
  }
  .v1223_137 {
    width: 195px;
    height: 50px;
    opacity: 1;
    position: absolute;
    top: 309px;
    left: 265px;
    overflow: hidden;
  }
  .v1223_138 {
    width: 195px;
    height: 50px;
    opacity: 1;
    position: absolute;
    top: 0px;
    left: 0px;
    box-shadow: 7.879000186920166px 7.879000186920166px 15px rgba(0.21960784494876862, 0.21960784494876862, 0.21960784494876862, 0.5019999742507935);
    overflow: hidden;
  }
  .v1223_139 img{
    width: 195px;
    height: 50px;
    opacity: 1;
    position: absolute;
    top: 0px;
    left: 0px;
  }
  .v1223_140 {
    width: 160px;
    color: rgba(255,255,255,1);
    position: absolute;
    top: 3px;
    left: 19px;
    font-family: Roboto Slab;
    font-weight: Black;
    font-size: 21px;
    opacity: 1;
    text-align: left;
  }
  .v1223_141 {
    width: 75px;
    height: 43px;
    opacity: 1;
    position: absolute;
    top: 43px;
    left: 325px;
    overflow: hidden;
  }
  
  .v1223_163 {
    width: 600px;
    color: rgba(125,127,128,1);
    position: absolute;
    top: 731px;
    left: 69px;
    font-family: Nunito;
    font-weight: Regular;
    font-size: 14px;
    opacity: 1;
    text-align: center;
  }
  .v1223_164 {
    width: 224px;
    height: 30px;
    opacity: 1;
    position: absolute;
    top: 694px;
    left: 255px;
    overflow: hidden;
  }
  .v1223_165 a{
    text-decoration: none !important;
    width: 49px;
    color: rgba(47,134,197,1);
    position: absolute;
    top: 5px;
    left: 0px;
    font-family: Helvetica;
    font-weight: Regular;
    font-size: 14px;
    opacity: 1;
    text-align: left;
  }
  .v1223_166 a{
    text-decoration: none !important;
    width: 29px;
    color: rgba(47,134,197,1);
    position: absolute;
    top: 5px;
    left: 195px;
    font-family: Helvetica;
    font-weight: Regular;
    font-size: 14px;
    opacity: 1;
    text-align: left;
  }
  .v1223_167 a{
    text-decoration: none !important;
    width: 88px;
    color: rgba(47,134,197,1);
    position: absolute;
    top: 5px;
    left: 82px;
    font-family: Helvetica;
    font-weight: Regular;
    font-size: 14px;
    opacity: 1;
    text-align: left;
  }
  .v1223_168 {
    height: 30px;
    opacity: 1;
    position: absolute;
    top: 0px;
    left: 64px;
    border: 1px solid rgba(88,89,91,1);
  }
  .v1223_169 {
    height: 30px;
    opacity: 1;
    position: absolute;
    top: 0px;
    left: 181px;
    border: 1px solid rgba(88,89,91,1);
  }
  .v1223_170 {
    width: 57px;
    height: 38px;
    
    opacity: 1;
    position: absolute;
    top: 242px;
    left: 334px;
    overflow: hidden;
  }
  .containerPassword{
    position: relative;
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    padding: 36px 48px;
    border-radius: 10px;
    text-align: center;
    width: 65%;
    height: 90%;
    top: 40px;
    left: 10%;
  }
  .rowPassword2{
    padding: 7%;
  }
  .rowPassword3{
    padding-bottom: 26%;
  }
  .textP1{
    font-family: 'Roboto Slab';
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 32px;
    color: #7D7F80;
  }
  .textP2{
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #7D7F80;
    margin-top: 20px;
  }
  .resetPasswordText{
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #7D7F80;
  }
  .resetPasswordA{
    background: #0A1330;
    font-family: 'Roboto Slab';
    font-style: normal;
    font-weight: 900;
    font-size: 21px;
    line-height: 28px;
    color: #FFFFFF;
    width: 215px;
    height: 33px;
    border-radius: 0%;
    padding-top: 1%;
  }
  .resetPasswordA:hover{
    color: #FFFFFF;
  }
  .a1{
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #2F86C5;
    padding-left: 10px;
    padding-right: 10px;
  }
  .passwordInput{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16.632px;
    line-height: 19px;
    color: #7D7F80;
    border-bottom: 0.594px solid #7D7F80;
    width: 310px;
  }
  .resetPassword{
    background: #0A1330;
    font-family: 'Roboto Slab';
    font-style: normal;
    font-weight: 900;
    font-size: 21px;
    line-height: 28px;
    color: #FFFFFF;
    width: 195px;
    height: 33px;
    border-radius: 0%;
    padding-top: 1%;
  }
  .resetPassword:hover{
    color: #FFFFFF;
  }
  .footerPassword{
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #7D7F80;
  }
  @media screen and (min-width: 2500px) {
    .textP1{
      font-size: 29px;
    }
    .textP2{
      font-size: 19px;
    }
    .a1{
      font-size: 19px;
    }
    .passwordInput{
      font-size: 21.632px;
      width: 330px;
    }
    .resetPassword{
      font-size: 27px;
      width: 205px;
      height: 36px;
      padding-top: 1%;
    }
    .resetPasswordA{
      font-size: 27px;
      width: 265px;
      height: 36px;
      padding-top: 1%;
    }
    .footerPassword{
      font-size: 19px;
    }
  }
  