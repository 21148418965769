.text{
    font-family: "Roboto Slab";
    font-style: normal;
    font-weight: 900;
    font-size: 37.323px;
    line-height: 49px;
    color: #D3D3D3;
}
.textClean{
  font-family: 'Jost';
  font-style: normal;
  font-weight: 700;
  font-size: 37.323px;
  line-height: 54px;
  color: #000000;
}
.textnumber{
    font-family: 'Roboto Slab';
    font-style: normal;
    font-weight: 900;
    font-size: 37.323px;
    line-height: 49px;
    color: #D04102;
}
.textnumberClean{
  font-family: 'Jost';
  font-style: normal;
  font-weight: 700;
  font-size: 37.323px;
  line-height: 49px;
  color: #B1CB32;
}
.textContainer{
    max-width: 352px;
    padding: 40px 0px 40px 0px;
}
.textContainerClean{
  max-width: 362px;
  padding: 40px 0px 40px 0px;
}
#mainOrderContainer{
    position: absolute;
    left: 30.82%;
    max-width: 600px;
}
.text2{
    font-family: "Nunito";
    font-style: bold;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #D3D3D3;
    max-width: 186px;
}
.text2Clean{
  font-family: "Jost";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
  max-width: 186px;
}
.text4{
  font-family: "Nunito";
  font-style: bold;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: #D3D3D3;
  max-width: 306px;
}
.text4Clean{
  font-family: "Jost";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #000000;
  max-width: 306px;
}
.textnumber2{
  font-family: "Nunito";
  font-style: bold;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: #D04102;
}
.textnumber2Clean{
  font-family: "Jost";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #B1CB32;
}
.text3{
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 24px;
  color: #D3D3D3;
}
.text3Clean{
  font-family: "Jost";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
}
#textContainer2{
    padding-bottom: 40px;
    width: 180px;
}

.btnA{
    border: 1px solid #8E9091;
    font-family: Helvetica;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #8E9091;
    background: transparent;
    width: 99,4px !important;
    height: 25,16px !important;
    border-radius: 6px;
    padding: 7px;
    margin: 3px;
    padding: 5px 25px 5px 25px;
}
.btnclkA{
    background: linear-gradient(90deg, #419E80 0%, #098067 100%);
    border: 1.641px solid #006838;
    font-family: Helvetica;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #CDCCC7;
    width: 99,4px !important;
    height: 25,16px !important;
    border-radius: 6px;
    padding: 7px;
    margin: 3px;
    padding: 5px 25px 5px 25px;
}
.btnAClean{
  border: 1px solid #8E9091;
  font-family: "Jost";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #8E9091;
  background: transparent;
  width: 99,4px !important;
  height: 25,16px !important;
  border-radius: 6px;
  padding: 7px;
  margin: 3px;
  padding: 5px 25px 5px 25px;
}
.btnclkAClean{
  background: #B1CB32;
  border: 1.641px solid #B1CB32;
  font-family: 'Jost';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  width: 99,4px !important;
  height: 25,16px !important;
  border-radius: 6px;
  padding: 7px;
  margin: 3px;
  padding: 5px 25px 5px 25px;
}
#btnB{
    background: #0A1330;
    font-family: Roboto Slab;
    font-style: normal;
    font-weight: 900;
    font-size: 14px;
    line-height: 18px;
    color: #8E9091;
    width: 99,4px !important;
    height: 25,16px !important;
    margin-left: 0%;
    padding: 4px 28.5px 4px 28.5px;

}
.proceeding{
    font-family: Roboto Slab;
    font-style: normal;
    font-weight: 900;
    font-size: 14px;
    line-height: 18px;
    color: #CDCCC7;
    background: linear-gradient(90deg, #419E80 0%, #098067 100%);
    border: 1.641px solid #006838;
    width: 99,4px !important;
    height: 25,16px !important;
    cursor: wait;
    border-radius: 6px;
    padding: 3.5px 5px 3.5px 5px;
}

quantity-picker {
    display: inline-block;
    border:1px solid #dfdfdf;
    border-radius: 3px;
    vertical-align:middle;
  }
  .quantity-input:focus {
    background: red;
  }
  
  .quantity-modifier,
  .quantity-display {
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    outline: none;
  }
  
  .quantity-modifier {
    height: 29px !important;
    width: 28px !important;
    border: 0 !important;
    background: #0A1330 !important;
    color: #8E9091 !important;
    text-align: center;
    cursor: pointer;
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 400;
    font-size: 18px !important;
    line-height: 21px;
  }
  .quantity-modifier:hover {
    background: #0A1330 !important;
    color: #555555;
  }
  .quantity-modifier:focus {
    outline:0;
  }
  .mod-disable {
    color:#0A1330 !important;
  }
  .mod-disable:hover {
    background-color: #0A1330 !important;
    color:#E0E0E0;
  }
  .quantity-display {
    height: 29px !important;
    width: 41px !important;
    /* padding: .5rem;s */
    border: 0 !important;
    border-top: 0 !important;
    border-bottom: 0 !important;
    text-align: center;
    color: #8E9091;
    background-color: #0A1330 !important;
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 400;
    font-size: 15px !important;
    line-height: 16px;
    background-color: #0A1330 !important;
  }
  .quantity-picker {
    display: inline-block;
    border: 0 !important;
    vertical-align: middle;
}
.modifier-left {
  border-right: 1px solid #8E9091 !important;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.modifier-right {
  border-left: 1px solid #8E9091 !important;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
#clkOrder{
  margin: 20px 10px 0px 0px;
}
@media screen and (min-width: 2500px) {
  .text{
    font-size: 44.323px;
  }
  .textnumber{
      font-size: 44.323px;
  }
  #textContainer{
    max-width: 422px;
  }
  .text2{
    font-size: 25px;
    max-width: 234px;
  }
  .text4{
    font-size: 25px;
    max-width: 356px;
  }
  .textnumber2{
      font-size: 25px;
  }
  .text3{
      font-size: 25px;
  }
  #textContainer2{
      padding-bottom: 40px;
      width: 260px;
      margin-left: 6%;
  }
  .btnA{
    font-size: 18px;
    width: 103,4px !important;
    height: 26,16px !important;
    border-radius: 6px;
    padding: 7px;
    margin: 3px;
    padding: 9px 25px 8px 25px;
  }
  .btnclkA{
      font-size: 18px;
      padding: 9px 25px 8px 25px;
  }
  #btnB{
      font-size: 18px;
      padding: 6px 28.5px 6px 28.5px;

  }
  .proceeding{
      font-size: 18px;
      padding: 5.5px 5px 5.5px 5px;
  }
  .quantity-modifier {
    height: 30px !important;
    width: 29px !important;
    font-size: 20px !important;
  }
  .quantity-display {
    height: 30px !important;
    width: 42px !important;
    font-size: 17px !important;
  }
}