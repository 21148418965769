.modal-dialog{
    max-width: 410px;
    margin-top: 5%;
}
.modal-body{
    padding-left: 35px;
}
#selectrole{
    border-radius: 0.25rem;
    border: 1px solid #D2D2D2;
    font-family: 'Nunito';
    font-style: italic;
    font-weight: 200;
    font-size: 14px;
    line-height: 19px;
    color: #858788;
    height: 20px;
    width: 335px;
}
#add{
    font-family: 'Roboto Slab';
    font-style: normal;
    font-weight: 900;
    font-size: 21px;
    line-height: 28px;
    color: #FFFFFF;
    background: #0A1330;
    color:#FFFFFF;
    border: 0px;
    border-radius: 0%;
    width: 130px;
    height: 32px;
    padding-top: 1%;
}
#headeradd{
    font-family: 'Roboto Slab';
    font-style: normal;
    font-weight: 900;
    font-size: 21px;
    line-height: 28px;
    color: #0A1330;
}
#addInput{
    border: 1px solid #D2D2D2;
    font-family: 'Nunito';
    font-style: italic;
    font-weight: 200;
    font-size: 14px;
    line-height: 19px;
    color: #858788;
    height: 20px;
    width: 335px;
}
#labelrequired{
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 200;
    font-size: 9px;
    line-height: 12px;
}
#labelp{
    font-family: 'Nunito';
    font-style: italic;
    font-weight: 300;
    font-size: 9px;
    line-height: 12px;
    color: #858788;
    width: 350px;
}
#permissions{
    font-family: 'Roboto Slab';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #808080;
}
#labelRole{
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    font-size: 9px;
    line-height: 12px;
    color: #858788;
}
.textBold{
    font-family: 'Nunito';
    font-style: bold;
    font-weight: bold;
    font-size: 9px;
    line-height: 12px;
    color: #858788;
}
.textStar{
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 200;
    font-size: 9px;
    line-height: 12px;
    color: #DC4407;
}
#labelform{
    font-family: 'Roboto Slab';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #0A1330;
}
@media screen and (min-width: 2500px) {
    #headeradd{
        font-size: 26px;
    }
    #addInput{
        font-size: 19px;
        height: 25px;
        width: 335px;
    }
    #selectrole{
        font-size: 19px;
        height: 25px;
        width: 335px;
    }
    #labelp{
        font-size: 14px;
        width: 350px;
    }
    #permissions{
        font-size: 19px;
    }
    #labelRole{
        font-size: 14px;
    }
    .textBold{
        font-size: 14px;
    }
    #labelform{
        font-size: 19px;
    }
    #labelrequired{
        font-size: 14px;
    }
  }