.defineSimButton{
    background: linear-gradient(90deg, #41A486 0%, #098067 100%);
    border: 1.066px solid #006838;
    margin-top: 36px;
    padding-top: 7px;
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    height: 25px;
    color: #FFFFFF;
    height: 35px;
    margin-left: 4.3%;
}
.defineSimButtonClean{
    background: #B1CB32;
    border: 1.13px solid #B1CB32;
    margin-top: 36px;
    padding-top: 7px;
    font-family: 'Jost';
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    height: 25px;
    color: #FFFFFF;
    height: 35px;
    margin-left: 4.3%;
}
.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl{
    margin-left: 0;
  }

.statusSim{
    max-width: 265px !important;
}
.payPal{
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 14px;
    color: #FFFFFF;
    background: #0A1330;
    border-radius: 4px;
    padding: 3px 8px 3px 8px;
}
@media screen and (min-width: 2500px) {
    .statusSim{
        max-width: 310px !important;
    }
  }