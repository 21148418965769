#lblactivation {
    font-family: "Roboto Slab";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #FFFFFF;
}

#inputactivaton {
    width: 30%;
    height: 23px;
    font-family: "Nunito";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 19px;
    background: #D3D2CD;
    color: #646566;
}

#previousbtn {
    font-family: Roboto Slab;
    font-style: normal;
    font-weight: 900;
    font-size: 21px;
    line-height: 28px;
    border-radius: 0%;
    color: #7D7F80;
    background: #0A1330;
    max-width: 121px;
    height: 40px;
    position: absolute;
    /* left: 75.67%; */
    right: 10%;
    /* top: 86.96%; */
    bottom: 0%;
}

#nextFirstBtn{
    font-family: Roboto Slab;
    font-style: normal;
    font-weight: 900;
    font-size: 21px;
    line-height: 28px;
    border-radius: 0%;
    color: #FFFFFF;
    background: #0A1330;
    max-width: 78px;
    height: 40px;
    position: absolute;
    right: 4.81%;
    bottom: 0%;
}

#nextbtn {
    font-family: Roboto Slab;
    font-style: normal;
    font-weight: 900;
    font-size: 21px;
    line-height: 28px;
    border-radius: 0%;
    color: #FFFFFF;
    background: #0A1330;
    max-width: 78px;
    height: 40px;
    position: absolute;
    /* left: 83.67%; */
    right: 4.81%;
    /* top: 86.96%; */
    bottom: 0%;
}

#previous2btn {
    font-family: Roboto Slab;
    font-style: normal;
    font-weight: 900;
    font-size: 21px;
    line-height: 28px;
    border-radius: 0%;
    color: #FFFFFF;
    background: #0A1330;
    max-width: 115px;
    height: 40px;
    position: absolute;
    right: 11.42%;
    bottom: 0%;
}

#submitbtn {
    font-family: Roboto Slab;
    font-style: normal;
    font-weight: 900;
    font-size: 21px;
    line-height: 28px;
    border-radius: 0%;
    color: #FFFFFF;
    background: #0A1330;
    max-width: 100px;
    height: 40px;
    position: absolute;
    right: 4.81%;
    bottom: 0%;
}

#finishbtn {
    font-family: Roboto Slab;
    font-style: normal;
    font-weight: 900;
    font-size: 21px;
    line-height: 28px;
    border-radius: 0%;
    position: absolute;
    right: 4.81%;
    bottom: 0.23%;
    color: #FFFFFF;
    background: #0A1330;
    max-width: 101px;
    height: 40px;
    border: 0px;
}


#lblfinish {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 21px;
    line-height: 29px;
    color: #FFFFFF;
}

#customeri {
    width: 96%;
    height: 23px;
    font-family: Nunito;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 19px;
    border-radius: 5px;
    background: #D3D2CD;
    color: #646566;
}

#customer {
    width: 98%;
    height: 23px;
    font-family: Nunito;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 19px;
    background: #D3D2CD;
    color: #646566;
}

#star {
    color: #D04102;
}

#ptxt {
    font-family: Nunito;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 19px;
    color: #D3D2CD;
}

#simnumber {
    font-family: Nunito;
    font-style: italic;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: #D3D2CD;
    padding-left: 12px;
}

#txt {
    font-family: Nunito;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 19px;
    color: #D3D2CD;
    padding: 0px;
    margin: 0px;
}

.card {
    background-color: transparent;
    border: 0;
    /* margin-top: 10px; */
    box-shadow: 0px 0px 0px rgb(0 0 0 / 0%) !important;
}

.cardo {
    width: 180px;
    height: 180px;
    border-radius: 3%;
    /* background: #0A1330; */
    padding: 10px;
    padding: 0px 15px 10px 19px;
}

.cordoClk {
    width: 180px;
    height: 180px;
    border-radius: 3%;
    /* background: #0A1330; */
    padding: 10px;
    padding: 0px 15px 10px 19px;
    outline: 2px solid #098067;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 42px;
}

.cardt {
    width: 180px;
    height: 180px;
    border-radius: 3%;
    /* background: #181727; */
    padding: 10px;
    padding: 0px 15px 10px 19px;
}

.cardtr {
    width: 180px;
    height: 180px;
    border-radius: 3%;
    /* background: #DB4401; */
    padding: 10px;
    padding: 0px 15px 10px 19px;
}

.cardf {
    width: 180px;
    height: 180px;
    border-radius: 3%;
    /* background: #D3D2CD; */
    color: #0A1330 !important;
    padding: 10px;
    padding: 0px 15px 10px 19px;
}

.cardg {
    width: 180px;
    height: 180px;
    border-radius: 3%;
    /* background: #D3D2CD; */
    color: #D3D2CD !important;
    padding: 10px;
    padding: 0px 15px 10px 19px;
}

.abc{
    max-width: 185px !important;
}

#numbernet {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 42px;
    color: #FFFFFF;
    padding-left: "20px";
}

#net {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 25px;
    color: #FFFFFF;
    padding-left: "20px";
}

#money {
    font-family: Roboto;
    font-style: italic;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: #FFFFFF;
    padding-left: "20px";
    margin-top: 0px;

}

#pcard {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 14px;
    color: #949697;
    padding-left: "20px";
    padding-top: 4px;

}

.selectBtn {
    border: 1px solid #949697;
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    background-color: transparent;
    color: #949697;
    border-radius: 5px;
    width: 110px;
    height: 28px;
    margin-top: 16px;
}
.selectedBtn {
    border: 1px solid #D1D0CB;
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    background-color: transparent;
    color: #D1D0CB;
    border-radius: 5px;
    width: 110px;
    height: 28px;
    margin-top: 16px;
}
.selected2Btn {
    border: 1px solid #D3D2CD;
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    background-color: transparent;
    color: #D3D2CD;
    border-radius: 5px;
    width: 110px;
    height: 28px;
    margin-top: 16px;
}
.selectClkBtn {
    font-family: "Nunito";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    background: linear-gradient(90deg, #41A486 0%, #098067 100%);
    border: 1.641px solid #fbfbfb;
    color: #D3D2CD;
    border-radius: 5px;
    width: 110px;
    height: 28px;
    margin-top: 16px;
}

.dataBtn {
    background: #D3D2CD;
    opacity: 0.5;
    width: 170px;
    height: 40px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 23px;
    line-height: 31px;
    text-align: left;
    /* padding-left: 4%; */
    padding: "10px";
    margin: "10px";
    display: flex;
    justify-content: space-between;
    color: #414042;
    border-radius: 3px;
    border: 0px;
    padding-top: 5px;
}
.dataNBClkBtn {
    opacity: 0.5;
    background: #D3D2CD;
    width: 200px;
    height: 50px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 13.223px;
    line-height: 15px;
    color: #414042;
    margin-top: "10px";
    display: flex;
    justify-content: space-between;
    border-radius: 3px;
    border: 0px;
    padding-top: 4%;
}
.dataNBClkBtn:focus {
    opacity: 1;
    background: linear-gradient(90deg, #41A486 0%, #098067 100%);
    width: 200px;
    height: 50px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 13.223px;
    line-height: 15px;
    color: #D3D2CD;
    padding: "10px";
    margin: "10px";
    display: flex;
    justify-content: space-between;
    border-radius: 3px;
    border: 1.641px solid #006838 !important;
    padding-top: 3%;
    padding-left: 2%;
}
.dataBtn:focus {
    color: #D3D2CD;
    opacity: 1;
    background: linear-gradient(90deg, #41A486 0%, #098067 100%);
    border: 1.641px solid #006838;
    border-radius: 3px;
    border: 0px;
}

.selectedPlan {
    background: linear-gradient(90deg, #41A486 0%, #098067 100%);
    border: 1.641px solid #006838;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 31px;
    color: #D3D2CD;
    width: 140.98px;
    padding-left: 15px;
}

.lblDataPlan {
    font-family: 'Roboto Slab';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #FFFFFF;
}
.lblactivationText1{
    font-family: 'Nunito';
    font-style: italic;
    font-weight: 300;
    font-size: 14px;
    line-height: 19px;
    color: #D3D2CD;
    padding-top: 40%;
    padding-bottom: 60%;
}
/* .dataClkBtn{  
    color: #D3D2CD;
    opacity: 1;
    background: linear-gradient(90deg, #41A486 0%, #098067 100%);
    border: 1.641px solid #006838;
    border-radius: 3px;
} */

#txtright {
    font-family: Roboto;
    font-style: italic;
    font-weight: 400;
    font-size: 13.223px;
    line-height: 15px;
    text-align: right;
    color: #414042;
    padding-top: 10%;
}

.activationContainer {
    width: 98%;
}

#stepper1 {
    width: 90%;
}

.line {
    border: 2px solid #949697;
}

.activeCircle {
    background: #0A1330;
    color: #D04102;
    border: 2px solid #D04102;
    /* backgroundColor: activeColor,
    opacity: activeOpacity,
    borderWidth: (activeBorderColor ? defaultBorderWidth : 0),
    borderColor: activeBorderColor,
    borderStyle: activeBorderStyle, */
}

.title {
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    color: #6c757d;
    text-align: center;
    text-decoration: none;
}

.activeTitle {
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    color: #D04102;
    text-align: center;
    text-decoration: none;
    /* color: activeTitleColor,
    opacity: activeTitleOpacity, */
}
.detailsContainer{
    /* position: absolute; */
    margin-right: 0%;
    margin-left: 0%;
    padding-left: 0%;
    max-width: 70%;
}
.detailsContainer2{
    /* position: absolute; */
    margin-right: 0%;
    margin-left: 1%;
    padding-left: 0%;
    max-width: 100%;
}

#customera{
    width: 100%;
    height: 23px;
    font-family: Nunito;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 19px;
    background: #D3D2CD;
    color: #646566;
    
}
#customeria{
    width: 100%;
    height: 23px;
    font-family: Nunito;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 19px;
    border-radius: 5px;
    background: #D3D2CD;
    color: #646566;
}
.changePassword{
    background: #011218;
    font-family: 'Roboto Slab';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
    width: 60%;
    height: 40%;
    margin-top: 15%;
    margin-left: 40%;
}
.changePassword2{
    background: #011218;
    font-family: 'Roboto Slab';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
    width: 30%;
    height: 40.5%;
    margin-top: 6.5%;
}
.uploadimage{
    background: #011218;
    font-family: 'Roboto Slab';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
    width: 120px;
    height: 30px;
    margin-top: 6.5%;
}
  @media screen and (min-width: 2500px) {
    #pcard {
        padding-top: 20px;
        font-size: 14px;
    }
    #numbernet {
        font-size: 35px;
    }
    #money {
        padding-top: 5px;
        font-size: 23px;
    }
    .cardo {
        width: 220px;
        height: 220px;
    }
    .cordoClk{
        width: 220px;
        height: 220px;
    }
    .cardt {
        width: 220px;
        height: 220px;
    }
    .cardtr {
        width: 220px;
        height: 220px;
    }
    .cardf {
        width: 220px;
        height: 220px;
    }
    .cardg {
        width: 220px;
        height: 220px;
    }
    .selectBtn {
        font-size: 19px;
        width: 140px;
        height: 33px;
        margin-top: 30px;
    }
    .selectedBtn {
        font-size: 19px;
        width: 140px;
        height: 33px;
        margin-top: 30px;
    }
    .selected2Btn {
        font-size: 19px;
        width: 140px;
        height: 33px;
        margin-top: 30px;
    }
    .selectClkBtn {
        font-size: 19px;
        width: 140px;
        height: 33px;
        margin-top: 30px;
    }
    .dataBtn {
        width: 210px;
        height: 55px;
        font-size: 30px;
        padding: "10px";
        margin: "10px";
        padding-top: 12px;
    }
    .rightMoney{
        font-size: 17px !important;
    }
    .dataNBClkBtn {
        width: 200px;
        height: 50px;
        font-size: 13.223px;
        margin-top: "10px";
        padding-top: 4%;
    }
    .dataNBClkBtn:focus {
        width: 200px;
        height: 50px;
        font-size: 13.223px;
        padding: "10px";
        margin: "10px";
        padding-top: 3%;
        padding-left: 2%;
    }
    .lblDataPlan{
        font-size: 19px;
    }
    #lblactivation{
        font-size: 19px;
    }
    #inputactivaton {
        width: 30%;
        height: 30px;
        font-size: 19px;
        margin-top: 18px;
    }
    #nextFirstBtn{
        font-size: 26px;
        max-width: 108px !important;
        height: 45px;
    }
    #customeri {
        width: 96%;
        height: 30px;
        font-size: 19px;
    }
    #customer {
        width: 98%;
        height: 30px;
        font-size: 19px;
    }
    #previousbtn {
        font-size: 26px;
        max-width: 141px;
        height: 45px;
    }
    #nextbtn {
        font-size: 26px;
        max-width: 108px;
        height: 45px;
    }
    #simnumber {
        font-size: 17px;
        padding-left: 12px;
    }
    #txt {
        font-size: 17px;
        padding: 0px;
        margin: 0px;
    }
    .selectedPlan {
        font-size: 30px;
        width: 185.98px;
        /* height: 45px; */
        padding-left: 10px;
        padding-top: 6px;
    }
    #previous2btn {
        font-size: 26px;
        max-width: 155px;
        height: 45px;
    }
    #submitbtn {
        font-size: 26px;
        max-width: 140px;
        height: 45px;
    }
    #finishbtn {
        font-size: 26px;
        max-width: 141px;
        height: 45px;
    }
    #customera{
        height: 27px;
        font-size: 19px;
    }
    #customeria{
        height: 27px;
        font-size: 19px;
    }
    .changePassword{
        font-size: 19px;
        width: 75%;
        height: 40%;
        margin-top: 15%;
        margin-left: 25%;
    }
    .changePassword2{
        font-size: 19px;
        width: 42%;
        height: 43.5%;
    }
    #lblfinish {
        font-size: 26px;
    }
  }
 
